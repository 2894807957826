<template>
    <div class="container-fluid text-white p-4 p-md-5">

      <div class="m-auto align-middle text-start">
        <h2 class="m-auto p-0 text-vertical d-inline-block fw-bold">
          Ideal para pessoas e empresas que querem
        </h2>
      </div>

      <div class="d-block">

        <div class="d-block text-start align-top mt-md-5">
          <ul class="list-unstyled row">
            <li class="col col-12 col-md-6 mt-md-4 p-4">
              <h3 class="text-secondary">
                Criar uma startup com <i>core</i> em tecnologia
              </h3>
              <p>sem se preocupar com codificação, gerenciamento, testes e busca de desenvolvedores. Nós cuidamos de toda a parte técnica para você.</p>
              <button class="btn btn-outline-secondary text-secondary">
                Vamos lá! <i class="fa fa-arrow-right"></i>
              </button>
            </li>
            <li class="col col-12 col-md-6 mt-md-4 p-4">
              <h3 class="text-secondary">
                Criar ou terceirizar o setor de tecnologia
              </h3>
              <p>para alavancar o negócio por meio dos benefícios que a tecnologia proporciona.</p>
              <button class="btn btn-outline-secondary text-secondary">
                Vamos lá! <i class="fa fa-arrow-right"></i>
              </button>
            </li>
            <li class="col col-12 col-md-6 mt-md-4 p-4">
              <h3 class="text-secondary">
                Escalar o negócio
              </h3>
              <p>e levá-lo a outro patamar.</p>
              <button class="btn btn-outline-secondary text-secondary">
                Vamos escalar! <i class="fa fa-arrow-right"></i>
              </button>
            </li>
            <li class="col col-12 col-md-6 mt-md-4 p-4">
              <h3 class="text-secondary">
                Automatizar processos
              </h3>
              <p>para economizar tempo, dinheiro e outros recursos.</p>
              <button class="btn btn-outline-secondary text-secondary">
                Vamos automatizar! <i class="fa fa-arrow-right"></i>
              </button>
            </li>
            <li class="col col-12 col-md-6 mt-md-4 p-4">
              <h3 class="text-secondary">
                Criar um aplicativo ou sistemas web
              </h3>
              <p>parar tirar sua ideia do papel.</p>
              <button class="btn btn-outline-secondary text-secondary">
                Vamos criar! <i class="fa fa-arrow-right"></i>
              </button>
            </li>
            <li class="col col-12 col-md-6 mt-md-4 p-4">
              <h3 class="text-secondary">
                Criar ou melhorar seu site ou e-commerce
              </h3>
              <p>para proporcionar melhor experiência aos visitantes e clientes.</p>
              <button class="btn btn-outline-secondary text-secondary">
                Vamos lá! <i class="fa fa-arrow-right"></i>
              </button>
            </li>
          </ul>
        </div>

      </div>

    </div>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'CustomerProfile',
  setup () {
    const store = useStore()

    const data = reactive({
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )
    })

    return {
      ...toRefs(data)
    }
  }
}

</script>

<style scoped>

.container-fluid{
  background-color: var(--bg-dark-purple)
}

h3{
  font-size: 1.5rem;
}
p{
  font-size: 1rem;
}

ul li:hover{
  cursor: pointer;
}

ul li button{
  opacity: 0;
}
ul li button:hover{
  background-color: var(--secondary-color);
  color: white !important;
}

ul li:hover button{
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

h2{
  font-size: 3rem
}

@media only screen and (max-width: 400px){
  h2{
    font-size: 1.5rem
  }
}

</style>
