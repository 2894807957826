import { createStore } from 'vuex'

const store = createStore({
  state () {

    return {
      currentLanguage: {},
      currentTheme: 'dark-theme'
    }

  },

  getters: {
    currentLanguage (state: any) {

      return state.currentLanguage

    },
    currentTheme (state: any) {

      return state.currentTheme

    }
  },

  mutations: {
    setCurrentLanguage (state, currentLanguage) {

      state.currentLanguage = currentLanguage

    },
    setCurrentTheme (state, currentTheme) {

      state.currentTheme = currentTheme

    }
  }
})

export default store
