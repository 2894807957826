<template>
    <header>
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid pt-1 pb-1 ps-4 pe-4 pt-md-3 ps-md-5 pe-md-5">
          <a class="navbar-brand" href="#">
            <figure class="m-0">
              <img src="/assets/logo.svg"
                   alt="Logo"
                   height="50">
            </figure>
          </a>
          <!-- <div class="d-flex"> -->
          <button class="navbar-toggler" type="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false"
                  aria-label="Toggle navigation">
            <i class="fa-solid fa-bars text-primary"></i>
              
          </button>
          <div class="collapse navbar-collapse m-auto" id="navbarNav">
            <ul class="navbar-nav ms-auto me-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page"
                   href="#">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Serviços
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Portfólio
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Contato
                </a>
              </li>
              <!-- <li>
                <a class="me-5"
                   @click="share()">
                  <i class="fas fa-share-alt"></i>
                </a>
              </li> -->
              <!-- <li>
                <div class="dropdown d-inline-block">
                  <a class="me-5"
                     data-bs-toggle="dropdown">
                    <i class="fas fa-globe-americas"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item"
                           @click="setCurrentLanguage('pt-br')">
                      <small>Português</small>
                    </a></li>
                    <li><a class="dropdown-item"
                           @click="setCurrentLanguage('en')">
                      <small>English</small>
                    </a></li>
                  </ul>
                </div>
              </li>              -->
            </ul>
          </div>
          <!-- </div> -->
          
        </div>
      </nav>     
    </header>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Header',
  setup (_, { emit }) {
    const store = useStore()
    const data = reactive({
      currentTheme: computed(() =>
        store.getters.currentTheme
      ),
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )

    })

    function setCurrentTheme (theme) {
      emit('setCurrentTheme', theme)
    }
    function setCurrentLanguage (language) {
      emit('setCurrentLanguage', language)
    }

    function share () {
      Navigator.share({
        title: 'David Bertoncello',
        text: 'this.currentLanguage.header.share',
        url: 'https://davidbertoncello.dev'
      })
    }

    return {
      ...toRefs(data),
      setCurrentTheme,
      setCurrentLanguage,
      share
    }
  }
}

</script>

<style scoped>

.navbar-toggler{
  border-color: var(--primary-color)
}

header a{
  color: #cfcfcf;
  font-size: 1.2rem;
  transition: .2s;
}
header a:hover{
  color: #fff;
  transition: .2s;
  cursor: pointer;
}


@media only screen and (max-width: 600px){

}

</style>
