<template>
    <footer class="container-fluid pt-2 ps-5 pe-5 pb-2">

      <div class="row text-white p-0 mt-4">
        <p class="m-0">
          Feito com <i class="fa fa-heart"></i> e <i class="fas fa-coffee"></i>.
        </p>
      </div>
      <div class="row text-white">
        <p>Copyright &copy; 2022. Todos os direitos reservados.</p>
      </div>

    </footer>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {

  name: 'Footer',
  setup () {
    const store = useStore()

    const data = reactive({
      currentLanguage: computed(() => store.getters.currentLanguage)
    })

    return {
      ...toRefs(data)
    }
  }

}

</script>

<style scoped>

footer{
  font-size: .8rem
}

.container-fluid{
    background-color: #090909;
}

h4{
    font-weight: 600;
}

p {
    display: block;
    color: rgba(255,255,255,0.5);
    padding: 10px;
}

</style>
