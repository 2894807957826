<template>
    <div class="container-fluid text-white p-3 p-md-5">

      <div class="d-block text-start ps-3 pe-3 ps-md-5 pe-md-5">
        <h2 class="d-inline-block fw-bold m-auto p-0">
          {{ currentLanguage.projects.bld.title1 }}: <b class="text-orange">
            {{ currentLanguage.projects.bld.title2 }}
          </b>
        </h2>
        <p class="mt-4">
          &emsp; {{ currentLanguage.projects.bld.description }}
        </p>
      </div>


      <div class="row">
        <div class="col-12 col-md-5 m-auto align-middle text-end p-5 illustration">
          <figure><img src="/assets/images/notebook+smartphone.png" /></figure>
        </div>
        <div class="col-12 col-md-7 text-start p-5">

          <ul class="list-unstyled mt-5">
            <li>
              <b class="font-size-1-5 text-orange">
                + R$ 100 mil
              </b> em economia e receita todo mês, graças ao melhor controle de recursos
            </li>
            <li>
              <b class="font-size-1-5 text-orange">
                + 300 horas
              </b> são melhor investidas pela redução do tempo em tarefas rotineiras que hoje são automatizadas
            </li>
            <li>
              <b class="font-size-1-5 text-orange">
                + 3 novas operações
              </b> construídas a partir de várias soluções tecnológicas independentes
            </li>
            <li>
              <b class="font-size-1-5 text-orange">
                + 9 desenvolvedores
              </b> trabalhando em um time próprio da BLD para manter e expandir as soluções, proporcionando resposta rápida ao mercado e melhor aproveitamente das oportunidades
            </li>
          </ul>
        </div>

      </div>

    </div>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Projects',
  setup () {
    const store = useStore()

    const data = reactive({
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )
    })

    return {
      ...toRefs(data)
    }
  }
}

</script>

<style scoped>

.light-theme ul li{
  color: #111;
}
.light-theme ul li:after{
background-color: transparent !important;
}

.light-theme h2{
color: #666;
}
.light-theme p{
color: #666;
}

.container-fluid{
    padding-bottom: 200px;
}

figure img {
    max-width: 90%;
}

ul li {
    position:relative; /* so that pseudoelements are positioned relatively to their "li"s*/
    /* use padding-bottom instead of margin-bottom.*/
    margin-bottom: 0; /* This overrides previously specified margin-bottom */
    padding-bottom: 2.5em;
}

ul li:after {
    /* bullets */
    content: ' ';
    position: absolute;
    left: -22px;
    top: 10px;
    display: block;
    width: 14px;
    height: 14px;
    border: 3px solid #f26321 !important;
    background-color: #111 !important;
    border-radius: 50%;
}

ul li:before {
    /* lines */
    content:"";
    position: absolute;
    left: -16px; /* adjust manually */
    border-left: 2px solid rgba(255, 255, 255, 0.1);
    height: 100%;
    width: 1px;
}

ul li:first-child:before {
   /* first li's line */
   top: 6px; /* moves the line down so that it disappears under the bullet. Adjust manually */
}

ul li:last-child:before {
    /* last li's line */
   height: 6px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}

h2{
    font-size: 3rem
  }

@media (max-width: 768px) {
 .illustration{
    display: none;
  } 

  h2{
    font-size: 1.5rem
  }

}


</style>
