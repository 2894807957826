<template>
    <div class="container text-white p-3 p-md-5">

      <div class="row">
        <div class="m-auto align-middle text-start">
          <h2 class="m-auto text-vertical d-inline-block fw-bold">
            O que alguns clientes dizem
          </h2>
        </div>
        <div>
          <carousel :itemsToShow="1"
                    :itemsToScroll="1">
            <slide v-for="testimonial in testimonials"
                   :key="testimonial">
              <TestimonialItem :testimonial="testimonial"></TestimonialItem>
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>

    </div>
</template>

<script>

import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import TestimonialItem from '@/components/TestimonialItem.vue'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'Testimonials',
  components: {
    TestimonialItem,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },

  setup () {
    const store = useStore()

    const data = reactive({
      testimonials: [
        {
          name: 'Jhonatha de Oliveira',
          photo: '/assets/testimonials/jhonatha.jpeg',
          comment: '"O trabalho desenvolvido pelo David, foi de extrema qualidade e nível técnico. O projeto exigia um site de alto nível com uma integração com API externa e um cliente extremamente exigente. E desde o início o David atendeu as expectativas. Foi uma comunicação ativa e clara, facilitando toda a entrega. Se precisa de um desenvolvedor qualificado, ele é a pessoa certa."'
        },
        {
          name: 'Arnold Pugin',
          photo: '/assets/testimonials/arnold.png',
          comment: 'A Oritex é uma empresa muito atualizada, principalmente com relação às exigências da LGPD, além do atendimento personalizado e rapidez. Recomendamos muito a Oritex.'
        },
        {
          name: 'Peterson Becker',
          photo: '/assets/testimonials/peterson.jpeg',
          comment: 'A Oritex desenvolveu nosso site, que já está no ar há alguns anos e trouxe ótimos resultados em nosso ramo imobiliário. Com certeza fizemos a melhor escolha!'
        },
      ],

      currentLanguage: store.getters.currentLanguage
    })

    return {
      ...toRefs(data)
    }
  }
}

</script>

<style class="scss" scoped>

.card-body{
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
}
.card{
  background-color: transparent;
}

.card h3{
  font-size: 1rem
}

h2{
  font-size: 3rem;
}

@media only screen and (max-width: 400px){
  h2{
    font-size: 1.5rem
  }
}



</style>
