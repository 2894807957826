<template>
    <div class="container-fluid p-3 pt-md-4 ps-md-5 pe-md-5 pb-md-4">

      <div class="row">
        <div class="col-4">
          <div class="d-inline-block">
            <b class="me-4 text-primary">
              5+
            </b>
          </div>
          <div class="d-inline-block text-start">
            anos<br/>no mercado
          </div>
        </div>

        <div class="col-4">
          <div class="d-inline-block">
            <b class="me-4 text-primary">
              60+
            </b>
          </div>
          <div class="d-inline-block text-start">
            projetos<br/>desenvolvidos
          </div>
        </div>

        <div class="col-4">
          <div class="d-inline-block">
            <b class="me-4 text-primary">
              80+
            </b>
          </div>
          <div class="d-inline-block text-start">
            clientes e parceiros<br/> satisfeitos
          </div>
        </div>
      </div>

    </div>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {

  name: 'OurNumbers',
  setup () {
    const store = useStore()

    const data = reactive({

      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )
    })

    return {
      ...toRefs(data)
    }
  }

}

</script>

<style scoped>

h4{
    font-weight: 600;
}
.light-theme .container-fluid{
  background-color: #eee;
  color: #666;
  transition: .2s;

}

.dark-theme .container-fluid{
  background-color: #090909;
  color: #fff;
  transition: .2s;
}

b{
  font-size: 3rem
}

@media only screen and (max-width: 400px){

  .col-4 {
    font-size: .7rem
  }
  b{
    font-size: 1.5rem
  }


}

</style>
