<template>
    <div class="container text-white p-3 p-md-5">

      <div class="row">
        <div class="m-auto align-middle text-start">
          <h2 class="m-auto text-vertical d-inline-block font-size-3 fw-bold">
            Serviços
          </h2>
        </div>
        <div class="row text-start">
          <div class="card col-12 col-md-4 p-2">
            <div class="card-body">
              <i class="fa fa-code text-secondary"></i>
              <h3>
                Aplicações web
              </h3>
              <p>
                Desde o processo de concepção até a publicação
              </p>
            </div>
          </div>

          <div class="card col-12 col-md-4 p-2">
            <div class="card-body">
              <i class="fa fa-mobile-screen-button text-secondary"></i>
              <h3>
                Aplicativos Android e iOS
              </h3>
              <p>
                Desde o primeiro desenho até a publicação nas lojas
              </p>
            </div>
          </div>

          <div class="card col-12 col-md-4 p-2">
            <div class="card-body">
              <i class="fa fa-display text-secondary"></i>
              <h3>
                Websites e Landing Pages
              </h3>
              <p>
                De acordo com a estratégia da sua empresa ou do seu lançamento
              </p>
            </div>
          </div>

          <div class="card col-12 col-md-4 p-2">
            <div class="card-body">
              <i class="fa fa-shield text-secondary"></i>
              <h3>
                Segurança digital e LGPD
              </h3>
              <p>
                Soluções e treinamentos técnicos para LGPD e segurança digital, incluindo mecanismos de ataque e defesa.
              </p>
            </div>
          </div>

          <div class="card col-12 col-md-4 p-2">
            <div class="card-body">
              <i class="fa fa-brain text-secondary"></i>
              <h3>
                Inteligência artificial
              </h3>
              <p>
                Algoritmos personalizados de machine learning e inteligência artificial
              </p>
            </div>
          </div>

          <div class="card col-12 col-md-4 p-2">
            <div class="card-body">
              <i class="fa fa-cloud text-secondary"></i>
              <h3>
                Cloud e nuvem
              </h3>
              <p>
                Planejamento e implantação de infraestrutura em nuvem para servidores, big data e ciência de dados.
              </p>
            </div>
          </div>

        </div>
      </div>

    </div>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Services',
  setup () {
    const store = useStore()

    const data = reactive({
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )
    })

    return {
      ...toRefs(data)
    }
  }
}

</script>

<style class="scss" scoped>

.card-body i{
  font-size: 2rem;
}
.card-body h3{
  font-size: 1.5rem;
  margin-top: .5rem;
}
.card-body p{
font-size: .9rem;
}
.card{
  background-color: transparent;
}

.card h3{
  font-size: 1rem
}

</style>
