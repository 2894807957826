<template>
    <div class="container-fluid pt-4 pb-5 pt-md-3 ps-md-5 pe-md-5">
      <div class="d-block ps-5 pe-5">
        <div class="d-inline-block text-start align-middle col-12 col-md-6">
          <h1>
            Desenvolvemos
            <VueWriter class="type-writer text-primary"
                       :array="replace"
                       :typeSpeed="30"
                       :eraseSpeed="20"
                       caret="cursor" />
          </h1>
          <p class="text-grey">
            Somos uma empresa de tecnologia focada em desenvolver e manter as suas soluções. Vamos conversar? É sem compromisso.
          </p>

          <a class="btn btn-primary rounded-5 pt-2 pb-2 ps-3 pe-3"
             href="https://calendly.com/david-bertoncello"
             target="_blank">
            Iniciar uma conversa <i class="fa fa-arrow-right ms-1"></i>
          </a>
        </div>

        <div class="d-inline-block col-12 col-md-6 illustration">
          <figure class="border-2 border-black">
            <img src="/assets/intro2.svg"
                 width="600"
                 class="p-1" />
          </figure>
        </div>
      </div>
    </div>
</template>

<script>

import { reactive, computed, toRefs } from 'vue'
import { useStore } from 'vuex'
import VueWriter from 'vue-writer'

export default {
  name: 'Intro',
  components: {
    VueWriter
  },

  setup (_, { emit }) {
    const store = useStore()

    const data = reactive({
      replace: ['o seu aplicativo', 'a sua startup tech', 'o seu site', 'a sua automação', 'a sua solução'],
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )
    })

    return {
      ...toRefs(data)

    }
  }

}

</script>

<style scoped>

h1{
  max-width: 90%;
  color: white;
  font-weight: 700;
  font-size: 3rem;
}
h1 .type-writer span{
  max-width: 90%;
  color: var(--primary-color) !important;
  font-size: 3rem;
  font-weight: 700;
}
.type-writer /deep/ span{
    color: var(--primary-color) !important;
}
.is-typed /deep/ span.cursor {
  display: inline-block !important;
  width: 3px !important;
  background-color: #ccc !important;
  animation: blink 1s infinite !important;
}

p{
  font-size: 1.2rem;
}

@media only screen and (max-width: 400px){


  h1{
    font-size: 1.5rem
  }
  p{
    font-size: .9rem;
  }

  .illustration{
    display: none !important;
  }

}

</style>
