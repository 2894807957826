<template>
    <div class="p-1 p-md-4 ps-md-5 pe-md-5">
      <div class="d-block">
        <figure>
          <img :src="testimonial.photo"
               class="rounded-circle"
               width="100">
        </figure>
      </div>
      <div class="d-block">
        <i class="quote d-inline-block fas fa-quote-left me-3"></i>
        <p class="d-inline-block ps-2 pe-2 ps-md-5 pe-md-5">
          {{ testimonial.comment }}
        </p>
      </div>
      <span>{{ testimonial.name }}</span>
    </div>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'TestimonialItem',
  props: ['testimonial'],
  setup () {
    const store = useStore()

    const data = reactive({
      currentLanguage: computed(() =>
        store.getters.currentLanguage
      )
    })

    return {
      ...toRefs(data)
    }
  }
}

</script>

<style class="scss" scoped>

.quote{
  color: rgba(255, 255, 255, 0.8);
}
p{
  color: white;
  font-style: italic;
}

@media only screen and (max-width: 400px){
  p{
    font-size: 1rem
  }
}

</style>
